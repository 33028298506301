import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    code : {
        show: false,
        title: i18n.t('message.code'),
        sortable: true,
        column: 'code'
    },
    is_active : {
        show: true,
        title: i18n.t('message.is_active'),
        sortable: true,
        column: 'is_active'
    },
    price : {
        show: true,
        title: i18n.t('message.price'),
        sortable: true,
        column: 'price'
    },
    calculation_type : {
        show: true,
        title: i18n.t('message.calculation_type'),
        sortable: true,
        column: 'calculation_type'
    },
    currency_id : {
        show: true,
        title: i18n.t('message.currency_id'),
        sortable: true,
        column: 'currency_id'
    },
    weight: {
        show: true,
        title: i18n.t('message.weight_in_kg'),
        sortable: true,
        column: 'weight'
    },
    measurement_id : {
        show: false,
        title: i18n.t('message.measurement_id'),
        sortable: true,
        column: 'measurement_id'
    },
    categories : {
        show: false,
        title: i18n.t('message.categories'),
        sortable: true,
        column: 'categories'
    },
    created_at : {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
