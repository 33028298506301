import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    number : {
        show: true,
        title: i18n.t('message.number'),
        sortable: true,
        column: 'number'
    },
    is_active : {
        show: true,
        title: i18n.t('message.status'),
        sortable: true,
        column: 'is_active'
    },
    mfo : {
        show: true,
        title: i18n.t('message.mfo'),
        sortable: true,
        column: 'mfo'
    },
    currency_id : {
        show: true,
        title: i18n.t('message.currency_id'),
        sortable: true,
        column: 'currency_id'
    },
    money_amount : {
        show: true,
        title: i18n.t('message.money_amount'),
        sortable: true,
        column: 'money_amount'
    },
    outgoing_money : {
        show: true,
        title: i18n.t('message.outgoing_money'),
        sortable: true,
        column: 'outgoing_money'
    },
    remainder : {
        show: true,
        title: i18n.t('message.remainder'),
        sortable: true,
        column: 'remainder'
    },
    created_at : {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
