export const model = {
    id: null,
    kpTemplateId: null,
    date: '',
    currency_id: null,
    end_date: '',
    comment: '',
    products: [],
    created_at: '',
    updated_at: '', 
};