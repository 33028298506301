export const model = {
    id: null,
    name: '',
    code: '',
    status_id: '',
    sizes: [],
    colors: [],
    created_at: '',
    updated_at: '',
  };
  