import { state } from "./state";

export const getters = {
    list: state => state.list,
    types: state => state.types,
    inventory: state => state.inventory,
    model: state => state.model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort ,
    last_id: state => state.last_id,
    count: state => state.count,
    total_email_message_count: state => state.total_email_message_count,
    all_emails_messages: state => state.all_emails_messages
}