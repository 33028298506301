import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    check_list_id : {
        show: true,
        title: i18n.t('message.check_list_id'),
        sortable: true,
        column: 'check_list_id'
    },
    text : {
        show: true,
        title: i18n.t('message.text'),
        sortable: true,
        column: 'text'
    },
    is_done : {
        show: true,
        title: i18n.t('message.is_done'),
        sortable: true,
        column: 'is_done'
    },
    end_date : {
        show: true,
        title: i18n.t('message.end_date'),
        sortable: true,
        column: 'end_date'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
