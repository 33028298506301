import request from "@/utils/request";

export function index(params) {
    return request({
        url: "/filials",
        method: "get",
        params
    });
}
export function store(data) {
    return request({
        url: "/filials",
        method: "post",
        data
    });
}
export function inventory(params) {
    return request({
        url: "/filials/inventory",
        method: "get",
        params
    });
}

export function userFilials(params) {
    return request({
        url: "/filials/userFilials",
        method: "get",
        params
    });
}

export function show(id) {
    return request({
        url: `/filials/${id}`,
        method: "get"
    });
}
export function update(data) {
    return request({
        url: `/filials/${parseInt(data.get('id'))}`,
        method: "post",
        data
    });
}
export function destroy(id) {
    return request({
        url: `/filials/${id}`,
        method: "delete"
    });
}

export function printReceivedBarcodes(params) {
    console.log('printReceivedBarcodes');
    console.log(params);
    return request({
        url: `/filials/printReceivedBarcodes`,
        method: 'get',
        params
    })
}


