export const contact_people = {
  id : '',  
  full_name: '', 
  position: '', 
  phone: '', 
  email: '', 
  comment: '', 
  created_at: '',
  updated_at: ''  
};
  