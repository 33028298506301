import { index, show, store, getDealOffers, getLatestFiles, downloadLatestFile, downloadDealOffer, update, destroy } from '@/api/deals/dealOffers';

export const actions = {
    
    index({commit}, params = {}){
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit('SET_LIST', res.data.result.data.dealOffers)
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })  
        })
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getLatestFiles({ commit }, filial_id) {
        return new Promise((resolve, reject) => {
            getLatestFiles(filial_id)
                .then(res => {
                    commit("SET_LATEST_FILES", res.data.result.data.files);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    downloadLatestFile({ commit }, link){
        return new Promise((resolve, reject) => {
            downloadLatestFile(link).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    downloadDealOffer({ commit }, offer_id){
        return new Promise((resolve, reject) => {
            downloadDealOffer(offer_id).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    getDealOffers({ commit }, deal_id) {
        return new Promise((resolve, reject) => {
            getDealOffers(deal_id).then(res => {
                commit('SET_DEALOFFER_LIST', res.data.result.data.dealOffers)
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    
    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id).then(res => {
                commit("SET_MODEL", res.data.result.data.dealOffer);
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err)
            })
        })
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },
    
    emptyList({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_LIST");
            resolve()
        })
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    

}

