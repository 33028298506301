import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    sip_number : {
        show: true,
        title: i18n.t('message.sip_number'),
        sortable: true,
        column: 'sip_number'
    },
    sip_password : {
        show: true,
        title: i18n.t('message.sip_password'),
        sortable: true,
        column: 'sip_password'
    },
    server_url : {
        show: true,
        title: i18n.t('message.server_url'),
        sortable: true,
        column: 'server_url'
    },
    port : {
        show: true,
        title: i18n.t('message.port'),
        sortable: true,
        column: 'port'
    },
    extension_type : {
        show: true,
        title: i18n.t('message.extension_type'),
        sortable: true,
        column: 'extension_type'
    },
    phones : {
        show: true,
        title: i18n.t('message.phones'),
        sortable: true,
        column: 'phones'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
