import { state } from "./state";

export const getters = {
    list: state => state.list,
    latest_files: state => state.latest_files,
    inventory: state => state.inventory,
    model: state => state.model,
    dealOfferList: state => state.dealOfferList,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort ,
    count: state => state.count

}