import { i18n } from '@/utils/i18n';

export const columns = {
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    calldate : {
        show: true,
        title: i18n.t('message.calldate'),
        sortable: true,
        column: 'calldate'
    },
    caller : {
        show: true,
        title: i18n.t('message.caller'),
        sortable: true,
        column: 'caller'
    },
    called_to : {
        show: true,
        title: i18n.t('message.called_to'),
        sortable: true,
        column: 'called_to'
    },
    caller_name : {
        show: true,
        title: i18n.t('message.caller_name'),
        sortable: true,
        column: 'caller_name'
    },
    target_name : {
        show: true,
        title: i18n.t('message.target_name'),
        sortable: true,
        column: 'target_name'
    },
    billsec : {
        show: true,
        title: i18n.t('message.billsec'),
        sortable: true,
        column: 'billsec'
    },
    callStatus : {
        show: true,
        title: i18n.t('message.callStatus'),
        sortable: true,
        column: 'callStatus'
    },
    recordingfile : {
        show: true,
        title: i18n.t('message.recordingfile'),
        sortable: true,
        column: 'recordingfile'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
