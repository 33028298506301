import _ from 'lodash';
export default {
    data() {
        return {
            
        }
    },
    methods: {
        updateWeightAndSizes(row, type){
            if(type){
                if(type === 'width'){
                    this.$set(row, 'width_on_inches',  _.round(parseFloat(row.width) * this.$sm_to_inch, 2));
                }
                if(type === 'width_on_inches'){
                    this.$set(row, 'width',  _.round(parseFloat(row.width_on_inches) / this.$sm_to_inch, 2));
                }
        
                if(type === 'height'){
                    this.$set(row, 'height_on_inches',  _.round(parseFloat(row.height) * this.$sm_to_inch, 2));
                }
                if(type === 'height_on_inches'){
                    this.$set(row, 'height',  _.round(parseFloat(row.height_on_inches) / this.$sm_to_inch, 2));
                }
        
                if(type === 'length'){
                    this.$set(row, 'length_on_inches',  _.round(parseFloat(row.length) * this.$sm_to_inch, 2));
                }
                if(type === 'length_on_inches'){
                    this.$set(row, 'length',  _.round(parseFloat(row.length_on_inches) / this.$sm_to_inch, 2));
                }
                
                this.compareAndSetWeight(row);
            }
        },

        updatePoundAndKG(row, type){
            if(type === 'weight_in_kg'){
                this.$set(row, 'item_weight_in_pounds',  _.round(parseFloat(row.item_weight) * this.$kg_to_pound, 3));
            }else{
                this.$set(row, 'item_weight',  _.round(parseFloat(row.item_weight_in_pounds) / this.$kg_to_pound, 3));
            }
    
            this.compareAndSetWeight(row);
        },
    
        compareAndSetWeight(row) {
            let size_weight = (parseFloat(row.width || 0) * parseFloat(row.length || 0) * parseFloat(row.height || 0)) / 6000;
            let weight =  parseFloat(row.item_weight || 0) > size_weight ? parseFloat(row.item_weight || 0) : parseFloat(size_weight || 0);
    
            this.$set(row, 'weight',  _.round(parseFloat(weight), 3));
        },
    },
}
