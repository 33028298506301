export const mutations = {
    builderSpan (state, radio) {
        if(radio.layout === 1) {
            state.leftVersion = true
            state.radio = radio.layout
            state.mode = radio.background.mode
            state.oldVersion = false
            state.background = radio.background
        } else if (radio.layout === 2) {
            state.leftVersion = false
            state.oldVersion = true
            state.mode = true
            state.radio = radio.layout
            state.background = ''
        } else if (radio.layout === 3) {
            state.leftVersion = true
            state.oldVersion = false
            state.radio = radio.layout
            state.mode = true
            state.background = ''
        }
        this.commit('saveData')
    },
    saveData (state) {
        window.localStorage.setItem('mode', JSON.stringify(state.mode))
        window.localStorage.setItem('oldVersion', JSON.stringify(state.oldVersion))
        window.localStorage.setItem('leftVersion', JSON.stringify(state.leftVersion))
        window.localStorage.setItem('background', JSON.stringify(state.background))
        window.localStorage.setItem('radio', JSON.stringify(state.radio))
    },
    saveShortcuts (state, items) {
        state.items = items
    },
    toggleMenu (state) {
        state.is_expanded = !state.is_expanded
    },
    changeLockStatus(state, payload){
        state.lock.status = payload.status
    },
    setLockPassword(state, payload){
        state.lock.password = payload.password
    },
    setTheDate(state, payload){
        state.dateToday = payload.date
    },
    setLockTimer(state, payload) {
        if (payload) {
            state.getLockTimer.isTime = payload
            state.getLockTimer.disable = false
        } else {
            state.getLockTimer.isTime = payload
            state.getLockTimer.disable = true
        }
    },
    resetAllStorage (state) {
        localStorage.removeItem('mode')
        localStorage.removeItem('oldVersion')
        localStorage.removeItem('leftVersion')
        localStorage.removeItem('background')
        localStorage.removeItem('radio')
        state.getLockTimer = { isIdle: true, isTime: 0, disable: true }
        state.lock = { password: 0, status: false }
        localStorage.removeItem('vuex')
    }
}
