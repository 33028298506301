export const model = {
  id: null,
  user_id: null,
  title: '',
  comment: '',
  alert_before_minutes: 0,
  start: '', 
  participants: [], 
  created_at: '',
  updated_at: '',
};
  