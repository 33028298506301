import request from '@/utils/request';

export function dealGoalsIndex(params) {
    return request({
        url: '/dealGoals/dealGoalsIndex',
        method: 'get',
        params
    })
}

export function selectedGoalYears(params) {
    return request({
        url: '/dealGoals/selectedGoalYears',
        method: 'get',
        params
    })
}

export function store(data) {
    return request({
        url: '/dealGoals',
        method: 'post',
        data
    })
}
export function showGoals(params) {
    return request({
        url: `/dealGoals/showGoals`,
        method: 'get',
        params
    })
}


export function updateGoals(data) {
    return request({
        url: `/dealGoals/updateGoals`,
        method: 'put',
        data
    })
}

export function destroy(params) {
    return request({
        url: `/dealGoals/${params.year}`,
        method: 'delete',
        params
    })
}