<template>
    <div>
      <el-select
        :value="selected"
        @input="dispatch"
        :placeholder="placeholder || $t('message.product')"
        filterable
        clearable
        :size="size"
        :disabled="disabled"
        class="w-100"
        :loading="loadingData"
        remote 
        :remote-method="searchInventoryWithLoad"
      >
        <el-option
          v-for="(product, index) in productList"
          :key="'products-' + index"
          :label="product.name"
          :value="product.id"
        ></el-option>
      </el-select>
    </div>
  </template>
  <script>
  import { mapGetters, mapActions } from "vuex";
  export default {
    props: {
      size: {
        default: "small",
      },
      placeholder: {
        default: null,
      },
      id: {
        default: null,
      },
      disabled: {
        default: false,
      },
      
    },
    watch: {
      id: {
        handler: function (val) {
          if(val && !this.productList.find(el => el.id === this.id)){
            this.searchInventoryWithLoad();
          }else{
            this.selected = this.id;
          }
        },
        immediate: true,
      },
    },
    data() {
      return {
        selected: null,
        productList: [],
        loadingData: false
      };
    },
    computed: {
      ...mapGetters({
        products: "products/selectList",
      }),
    },
    mounted() {
      if (this.productList && this.productList.length === 0) 
        this.updateInventory()
            .then(res => {
                this.productList = JSON.parse(JSON.stringify(this.products));
            });
    },
    methods: {
      ...mapActions({
        updateInventory: "products/select",
      }),
      dispatch(e) {
        this.$emit("input", e);
        this.selected = e;
      },
      searchInventoryWithLoad(val = ''){
        this.loadingData = true;
        this.searchInventory(val, this);
      },
  
      searchInventory: _.debounce(function(val, self) {
          self.updateInventory({search: val})
            .then(() => {
              this.productList = JSON.parse(JSON.stringify(this.products));
              this.loadingData = false;
              setTimeout(() => {
                this.selected = this.id;
              }, 100);
            })
            .catch(() => this.loadingData = false)
      }, 500), 
    },
  };
  </script>
  